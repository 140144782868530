/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiNode, UiNodeInputAttributes } from '@ory/client'
import { useLocale } from 'context/localeContext'
import React from 'react'
import { Button as StyledButton } from 'vspry-style-components'
import { translateAuthCode } from './util'

type ButtonNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'input'; type: 'button' | 'submit' } & UiNodeInputAttributes }
export const isButtonNode = (a: UiNode): a is ButtonNode => {
    if (a.attributes.node_type === 'input' && (a.attributes.type === 'button' || a.attributes.type === 'submit')) return true
    return false
}

export default function Button({ attributes, meta }: ButtonNode) {
    const { translateMultivalue } = useLocale()
    const callWebauthnFunction = (functionBody: string) => {
        // eslint-disable-next-line no-new-func
        const run = new Function(functionBody)

        const intervalHandle = window.setInterval(() => {
            // eslint-disable-next-line no-underscore-dangle
            if ((window as any).__oryWebAuthnInitialized) {
                run()
                window.clearInterval(intervalHandle)
            }
        }, 100)

        return intervalHandle
    }

    const onClick = (e: React.MouseEvent | React.FormEvent<HTMLFormElement>) => {
        if (attributes.onclick) {
            e.stopPropagation()
            e.preventDefault()
            callWebauthnFunction(attributes.onclick)
        }
    }

    return (
        <StyledButton
            {...attributes}
            id={`auth-input-${attributes.name}`}
            onClick={onClick}
            $fitted
            color={meta.label?.text === 'Sign up' ? 'highlight' : 'accent'}
        >
            {translateAuthCode(translateMultivalue, meta.label)}
        </StyledButton>
    )
}
